import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import { FaPaw, FaCalendarAlt, FaUserFriends, FaAppStoreIos, FaHome, FaDog, FaShower, FaCheckSquare } from 'react-icons/fa';
import { MdMoneyOff, MdPets } from "react-icons/md";
import { FcAndroidOs } from "react-icons/fc";
import { GiJumpingDog } from "react-icons/gi";
import './Home.css';
import happyDog from '../images/happy-dog.png';

function Home() {
  return (
    <div className="home">
      <SEO
        title="Viveture - Pet Care Made Simple and Transparent"
        description="Viveture is a subscription-based pet care app for booking services like House Boarding, Sitting, Walking, and Checkins from Sitters. No service fees, just $5/month or $40/year."
        canonical="/"
      />

      <section className="hero">
        <div className="hero-content">
          <h1>Pawsome Pet Care<br />at Your Fingertips</h1>
          <h2>Simple. Transparent. Affordable.</h2>
          <h3>Connect with trusted pet sitters for just $5/month <br />or $40/year ($3.33 a month.)</h3>
          <Link to="/signup" className="cta-button-home pulse">Sign Up for Early Access</Link>
          <div className="app-badges">
            <p>Coming Soon:</p>
            <div>
              <FaAppStoreIos className="app-store-badge" />
              <FcAndroidOs className="google-play-badge" />
            </div>
          </div>
        </div>
        <div className="hero-image">
          <img src={happyDog} alt="Happy dog" />
        </div>
      </section>

      <section className="vision-statement">
        <h2>Our Vision</h2>
        <p className="main-vision">
          "At Viveture, we believe in simplicity and fairness. Our vision is to prove that it's possible to offer a top-notch user experience at an affordable cost, while being profitable without exploiting our users or service providers."
        </p>
        <div className="vision-points">
          <div className="vision-point">
            <h3>Simplicity in Design</h3>
            <p>We're committed to creating an intuitive, easy-to-use platform that simplifies pet care arrangements.</p>
          </div>
          <div className="vision-point">
            <h3>Affordability Without Compromise</h3>
            <p>We're dedicated to keeping our service affordable for pet owners without sacrificing quality or features.</p>
          </div>
          <div className="vision-point">
            <h3>Fair Compensation</h3>
            <p>We envision a model where pet sitters receive 100% of their earnings, proving that platforms can succeed without taking large cuts from service providers.</p>
          </div>
          <div className="vision-point">
            <h3>Transparent Pricing</h3>
            <p>We believe in clear, upfront pricing with no hidden fees or surprises.</p>
          </div>
          <div className="vision-point">
            <h3>Ethical Profitability</h3>
            <p>Our goal is to demonstrate that it's possible to run a successful, profitable business while treating all stakeholders fairly.</p>
          </div>
        </div>
      </section>

      <section className="services">
        <h2>Our Services</h2>
        <div className="services-grid">
          <div className="service-item">
            <FaHome className="service-icon" />
            <h3>House Sitting</h3>
            <p>Care for your pets in the comfort of your own home.</p>
          </div>
          <div className="service-item">
            <MdPets className="service-icon" />
            <h3>House Boarding</h3>
            <p>Your pet stays overnight in the sitter's home.</p>
          </div>
          <div className="service-item">
            <GiJumpingDog className="service-icon" />
            <h3>Dog Walking</h3>
            <p>Regular walks to keep your dog healthy and happy.</p>
          </div>
          <div className="service-item">
            <FaShower className="service-icon" />
            <h3>Dog Wash</h3>
            <p>Keep your furry friend clean and fresh.</p>
          </div>
          <div className="service-item">
            <FaDog className="service-icon" />
            <h3>Wash + Walk</h3>
            <p>Combine a refreshing wash with a healthy walk.</p>
          </div>
          <div className="service-item">
            <FaCheckSquare className="service-icon" />
            <h3>Check-ins</h3>
            <p>Brief visits to check on your pet's well-being.</p>
          </div>
        </div>
      </section>

      <section className="features">
        <h2>Why Pet Parents & Sitters Love Viveture</h2>
        <div className="feature-grid">
          <div className="feature-item">
            <FaPaw className="feature-icon" />
            <h3>Tailored Care</h3>
            <p>From cuddles to adventures, find the perfect sitter for your pet's needs.</p>
          </div>
          <div className="feature-item">
            <FaCalendarAlt className="feature-icon" />
            <h3>Flexible Booking</h3>
            <p>Last-minute plans? No problem. Book reliable care in minutes.</p>
          </div>
          <div className="feature-item">
            <MdMoneyOff className="feature-icon" />
            <h3>No Hidden Fees</h3>
            <p>Pay sitters directly. We don't take a cut from their earnings.</p>
          </div>
          <div className="feature-item">
            <FaUserFriends className="feature-icon" />
            <h3>Verified Sitters</h3>
            <p>Rest easy knowing your fur babies are in caring, capable hands.</p>
          </div>
        </div>
      </section>

      <section className="how-it-works">
        <h2>How Viveture Works</h2>
        <div className="steps">
          <div className="step">
            <div className="step-number">1</div>
            <h3>Sign Up</h3>
            <p>Create your account and tell us about your pets.</p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <h3>Find a Sitter</h3>
            <p>Browse profiles and choose your perfect match.</p>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <h3>Book & Pay</h3>
            <p>Schedule the service and pay your sitter directly.</p>
          </div>
          <div className="step">
            <div className="step-number">4</div>
            <h3>Enjoy</h3>
            <p>Relax knowing your pet is in good hands!</p>
          </div>
        </div>
      </section>

      <section className="sitter-callout">
        <h2>Attention Pet Sitters!</h2>
        <p>Tired of sharing your hard-earned money?</p>
        <p>Try Viveture, and keep 100% of what you earn. No fees, no commissions. No Strings attached.</p>
        <Link to="/signup" className="cta-button-home">Sign up for early access!</Link>
      </section>

      {/* <section className="testimonials">
        <h2>What Our Users Say</h2>
        <div className="testimonial-grid">
          <div className="testimonial">
            <p>"Viveture made finding a reliable sitter for my anxious rescue so easy!"</p>
            <p className="testimonial-author">- Sarah K., Dog Mom</p>
          </div>
          <div className="testimonial">
            <p>"I love the flexibility and extra income I get as a Viveture sitter!"</p>
            <p className="testimonial-author">- Mike R., Pet Sitter</p>
          </div>
        </div>
      </section> */}

      {/* <section className="cta">
        <h2>Ready to Join the Viveture Family?</h2>
        <p>Start your free trial today and experience hassle-free pet care!</p>
        <Link to="/signup" className="cta-button pulse">Get Started Now</Link>
      </section> */}
    </div>
  );
}

export default Home;